import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useUser } from "@auth0/nextjs-auth0"

import SearchBar from "../components/SearchBar"
import BottomBlock from "../components/BottomBlock"
import Discover from "../components/Discover"
import Categories from "../components/Categories"
import Image from "../components/CustomImage"
import data from "../data/index.json"

export async function getStaticProps() {
  return {
    props: {
      nav: {
        light: true,
        classes: "shadow",
        color: "white",
      },
      title: data.title || "Top IT Companies - Rankings & Reviews",
      meta: data.meta,
    },
  }
}

//--
const Index = () => {
  const { user } = useUser();
  return (
    <React.Fragment>
      {data.hero && (
        <section className="d-flex align-items-center dark-overlay" style={{ background: " #0b0b3c" }}>
          <Image
            src={`/content/${data.hero.img}`}
            layout="fill"
            className="bg-image"
            alt="Hero image"
            loading="eager"
            priority={true}
          />
          <Container className="py-6 py-lg-7 text-white overlay-content text-center z-index-20">
            <Row>
              <Col xl="12" className="mx-auto">
                <h1 className="display-3 fw-bold text-shadow">
                  {data.hero.title}
                </h1>
                <p className="text-lg text-shadow">{data.hero.subTitle}</p>
              </Col>
            </Row>
          </Container>
        </section>
      )}
      <Container>
        <SearchBar
          options={data.searchOptions}
          className="rounded p-3 p-lg-4 position-relative mt-n5 z-index-20"
          halfInputs
          btnMb="0"
        />
      </Container>
      {data.numberedBlocks && (
        <section className="py-6">
          <Container>
            <Row>
              {data.numberedBlocks.map((block, index) => (
                <Col lg="4" key={index} className="position-relative px-5" >
                  <p className="advantage-number">{index + 1}</p>
                  <div className="ps-lg-5">
                    <h2 className="h6 text-uppercase">{block.title}</h2>
                    <p className="text-muted text-sm mb-5 mb-lg-0">
                      {block.content}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
      <Categories greyBackground />
      {data.btnBlocks[user ? 1 : 0].bottomBlock && (
        <BottomBlock bottomBlock={data.btnBlocks[user ? 1 : 0].bottomBlock}
        />
      )}
      {data.discover && (
        <Discover
          className="py-6"
          title={data.discover.title}
          subTitle={data.discover.subTitle}
          blocks={data.discover.blocks}
        />
      )}
    </React.Fragment>
  )
}

export default Index
